//样品申请单确认
<template>
  <div class="top_order_info flex_row_start_center" style="display:none;">
    <div class="top_logo">
      <div :style="`background-image:url(${configInfo.main_site_logo})`"></div>
    </div>

    <div class="top_info_progress flex_row_end_center">

      <div class="progress_item">
        <div class="progress flex_row_center_center">
          <span class="active">1</span>
          <div class="progress_line">
            <div :class="{ content: true, active: true, current: false }"></div>
          </div>
        </div>
        <p style="margin-left: -11px">{{ L['确认订单'] }}</p>
      </div>

      <div class="progress_item">
        <div class="progress flex_row_center_center">
          <span :class="{ active: false }">{{ pre_params.data.ifcart == 'true' ? 2 : 1 }}</span>
          <div class="progress_line">
            <div :class="{ content: true, active: false }"></div>
          </div>
        </div>
        <p>{{ L['付款'] }}</p>
      </div>

      <div class="progress_item" style="margin-left: -24px">
        <div class="progress flex_row_end_center">
          <span :class="{ active: false }">{{ pre_params.data.ifcart == 'true' ? 3 : 2 }}</span>
        </div>
        <p style="position:relative;left:12px;">{{ L['支付成功'] }}</p>
      </div>

    </div>
  </div>
  <div class="confirm_order_main">
    <div class="confirm_order_container">
      <div class="receive_info" v-if="virtual.isVG == 1">
        <template v-if="orderAddress.data">
          <div class="receive_info_title">
            <span>{{ L['收货信息'] }}</span>
            <div @click="addAddress">{{ L['新建地址'] }}</div>
          </div>
          <div class="receive_info_user">
            {{ orderAddress.data.memberName }}<span>{{ orderAddress.data.telMobile }}</span>{{
                orderAddress.data.addressAll
            }}
            {{ orderAddress.data.detailAddress }}
            <div @click="changeAddress">{{ L['更换地址'] }}</div>
          </div>
        </template>
        <template v-else>
          <div class="receive_info_title">
            <span>{{ L['收货信息'] }}</span>
          </div>
          <div class="receive_info_user_empty">
            <div @click="addAddress">{{ L['新建地址'] }}</div>
          </div>
        </template>
      </div>

      <!-- 预留信息 -->
      <div class="pre_message" v-else-if="virtual.isVG == 2 && virtual.virtualPre.length">
        <div class="pre_message_title">
          <span>{{ '预留信息' }}</span>
        </div>
        <div class="pre_message_info">
          <table>
            <tr v-for="(item, index) in virtual.virtualPre" :key="index">
              <td>
                <div class="tag_pre"><strong v-if="item.isRequired == 1">*</strong><span>{{ item.reserveName }}：</span>
                </div>
              </td>
              <td v-if="item.reserveType == 1 || item.reserveType == 3">
                <el-input id="pre_tag_input" v-model="item.reserveValue" :placeholder="`请输入${item.reserveName}`"
                  type="text" :maxlength="item.reserveType == 1 ? 11 : 50" clearable :key="index"
                  @input="preMsgErr.index = -1">
                </el-input>
              </td>
              <td v-else-if="item.reserveType == 5">
                <el-input id="pre_tag_input" v-model="item.reserveValue" :placeholder="`请输入${item.reserveName}`"
                  type="text" :maxlength="30" clearable :key="index" @input="preMsgErr.index = -1">
                </el-input>
              </td>
              <td v-else>
                <el-input id="pre_tag_input" v-model="item.reserveValue" :placeholder="`请输入${item.reserveName}`"
                  type="text" :maxlength="item.reserveType == 2 ? 18 : 50" clearable :key="index"
                  @input="preMsgErr.index = -1">
                </el-input>
              </td>
              <td v-if="index == preMsgErr.index">
                <span class="preMsgErr">{{ preMsgErr.errMsg }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 预留信息 -->

      <div class="store_item_title" v-if="goods_data.data.length > 0">
        <ul>
          <li>产品信息</li>
          <li></li>
          <li></li>
          <li>单价（元）</li>
          <li>数量</li>
        </ul>
      </div>

      <template v-if="goods_data.data.length > 0">
        <div class="store_item" :class="{ store_item_none: index == 0 }">
          <div class="store_name">
            <i class="iconfont icondianpu1"></i>
            <span>{{ goods_data.storeName }}</span>
            <!-- <img src="@/assets/cart/right_down.png" alt="" />
            <img class="storeChat" src="@/assets/cart/storeChat.png"/> -->
          </div>
          <div class="good_container">
            <div v-for="(goodItem, index) in goods_data.data" :key="index" class="good_item">
              <div class="flex_row_start_center">
                <div class="good_info flex_row_start_center">
                  <div class="good_image_con">
                    <div class="virtual_tag" v-if="false">虚拟</div>
                    <img class="good_image" :src="goodItem.goodsImage" alt />
                  </div>
                  <div class="good_info_text flex_column_between_start">
                    <div>
                      <div class="good_name overflow_ellipsis_two">{{ goodItem.goodsName }}</div>
                      <div class="good_spec overflow_ellipsis">{{ goodItem.specValues }}</div>
                    </div>
                    <div>
                      <p class="productCode" v-if="goodItem.orderCode">订货编码：{{ goodItem.orderCode }}</p>
                      <p class="goods_des_bottom">
                        {{ goodItem.produceState == 1 ? '现货' : goodItem.produceState == 2 ? '半成品' : '待产' }}
                        <span>发货日: {{ goodItem.deliverTime }}天</span>
                      </p>
                    </div>

                  </div>
                </div>
                <!-- 商品参数 -->
                <div class="goods_message">
                  <el-scrollbar>
                    <div v-for="attr in goodItem.attributeList" :key="attr">
                      {{ attr.attributeName }}：{{ attr.attributeValue }}
                    </div>
                  </el-scrollbar>
                </div>
                <div class="good_price flex_row_center_center">
                  <span>¥{{ goodItem.goodsPrice }}</span>
                </div>
                <div class="num">{{ goodItem.buyNum }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="order_total_settle">
        <div class="goBuy" @click="goBuy" style="cursor: pointer;">{{ L['提交订单'] }}</div>
      </div>

      <!-- 商品无货缺货弹窗 start -->
      <el-dialog :title="no_good_info.data.stateValue" v-model="out_stock_dialog" customClass="out_store_width"
        :before-close="handleClose" lock-sroll="false">
        <div class="out_stock_dialog">
          <div v-for="(outStockItem, index) in no_good_info.data.productList" :key="index" class="good_item flex">
            <img :src="outStockItem.goodsImage" alt />
            <div class="good_info">
              <span class="good_name overflow_ellipsis">{{ outStockItem.goodsName }}</span>
              <div class="spec_num">
                <span class="good_spec">{{ outStockItem.goodsSpec }}</span>
                <span class="good_num">*{{ outStockItem.buyNum }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_con flex_row_center_center" v-if="no_good_info.data.state == 2">
          <div class="return" @click="returnLastPage">{{ L['返回'] }}</div>
          <div class="remove" @click="clearFailureGoods">{{ L['移除无货产品'] }}</div>
        </div>
        <div class="btn_con flex_row_center_center" v-else>
          <div class="return red" @click="returnLastPage">{{ L['返回'] }}</div>
        </div>
      </el-dialog>
      <!-- 商品无货缺货弹窗 end -->

      <!-- 地址选择弹窗 start -->
      <el-dialog title="更换地址" v-model="show_select_address" customClass="select_address_width"
        :before-close="handleClose" lock-sroll="false">
        <div class="out_stock_dialog address_con">
          <div v-for="(item, index) in address_list.data" :key="index" @click="selectAddress(index)"
            :class="{ address_item: true, flex_column_start_start: true, select: current_address_index == index }">
            <span>{{ L['收货人'] }}：{{ item.memberName }}</span>
            <span>{{ L['联系方式'] }}：{{ item.telMobile }}</span>
            <div class="address_text" :title="item.addressAll + item.detailAddress">
              <span>{{ L['收货地址'] }}：</span>
              <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress }}</span>
            </div>
            <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
          </div>
        </div>
        <div class="btn_con flex_row_center_center">
          <div class="remove red" @click="confirmChangeAddress()">{{ L['提交修改'] }}</div>
        </div>
      </el-dialog>
      <!-- 地址选择弹窗 end -->

      <SldAddressOperation v-if="addressVisibleFlag" @close="close" @refreshAddress="refreshAddress" :visibleFlag="true"
        :type="type" />
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted, watch } from "vue";
import {
  ElDialog,
  ElInput,
  ElSwitch,
  ElMessage,
  ElSelect,
  ElOption,
  ElMessageBox
} from "element-plus";
import { useRoute, useRouter } from "vue-router";
import SldAddressOperation from "../../components/AddressOperation";
import { useStore } from 'vuex'
export default {
  name: "ConfirmRequest",
  components: {
    ElDialog,
    ElInput,
    ElSwitch,
    ElSelect,
    ElOption,
    SldAddressOperation
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore()
    const addressVisibleFlag = ref(false);
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const not_select = require("../../assets/buy/not_select.png");
    const selected = require("../../assets/buy/selected.png");
    const invoice_selected = require("../../assets/buy/invoice_selected.png");
    const couponCode = ref([])
    const platformCouponCode = ref("")
    const navlist = reactive({ data: [] });
    const show_change_address = ref(false);
    const edit_voice_dialog = ref(false);
    const add_voice_content = ref(false);
    const out_stock_dialog = ref(false);
    const type = ref("add");
    const no_good_info = reactive({ data: {} });
    const show_select_address = ref(false);
    const pre_params = reactive({ data: {} });
    const orderAddress = reactive({ data: "" });
    const address_list = reactive({ data: [] });
    const goods_data = reactive({ data: [] });
    const configInfo = ref(store.state.configInfo)
    const current_address_index = ref(0);
    const select_invoice_info = reactive({
      invoiceId: "",
      invoiceContent: "",
      invoiceTitle: ""
    });
    const selCouponStore = reactive({
      store: {},
      couponCode: ''
    })
    const rules = ref('')
    const invoice_list = reactive({ data: [] }); //发票列表
    const intRuleList = ref([])
    const invoice_info = reactive({
      data: {
        //新增发票信息
        bankAccount: "", //银行帐户(专用发票）
        bankName: "", //开户银行(专用发票）
        invoiceContent: 1, //发票内容：1-产品明细；2-产品类别
        invoiceTitle: "", //发票抬头(通用信息）
        invoiceType: 1, //发票类型：1-普通发票；2-增值税发票
        isDefault: false, //是否默认发票：0-非默认发票，1-默认发票
        receiverAddress: "", //收票人详细地址(通用信息）
        receiverEmail: "", //	收票邮箱(通用信息）
        receiverMobile: "", //收票人手机号(通用信息）
        receiverName: "", //收票人姓名(通用信息）
        registerAddr: "", //注册地址(专用发票）
        registerPhone: "", //注册电话(专用发票）
        taxCode: "", //纳税人识别号(通用信息）
        titleType: 1, //抬头类型：1-个人；2-公司
        companyName: "" //部门名称
      }
    });
    // 重置发票信息
    const resetVoiceInfo = () => {
      invoice_info.data.bankAccount = "";
      invoice_info.data.bankName = "";
      invoice_info.data.invoiceContent = 1;
      invoice_info.data.invoiceTitle = "";
      invoice_info.data.invoiceType = 1;
      invoice_info.data.isDefault = false;
      invoice_info.data.receiverAddress = "";
      invoice_info.data.receiverEmail = "";
      invoice_info.data.receiverMobile = "";
      invoice_info.data.receiverName = "";
      invoice_info.data.registerAddr = "";
      invoice_info.data.registerPhone = "";
      invoice_info.data.taxCode = "";
      invoice_info.data.titleType = 1;
      invoice_info.data.companyName = "";
    };
    const order_settle_info = reactive({
      totalAmount: "",
      totalDiscount: "",
      platformCouponList: [],
      platformCouponCode: ""
    });
    const allData = reactive({ data: {} })
    const showIntList = ref(false)
    const showIntRule = ref(false)
    const chosenInt = ref(0)
    const tmpInt = ref(0)
    const virtual = reactive({
      isVG: null,
      virtualPre: []
    })
    const preMsgErr = reactive({
      index: null,
      errMsg: ''
    })
    const isPreventClick = ref(false)
    const reserveInfoList = ref([])

    const changeCoupon = (storeItem, index) => {
      selCouponStore.store = storeItem
      selCouponStore.couponCode = couponCode.value[index]
      confirmOrder(2)
    }

    //用于切换地址，使用优惠券，获取信息，运费等
    const confirmOrder = type => {
      let param = {};
      param.source = type;
      param.addressId = orderAddress.data.addressId;
      param.platformCouponCode = platformCouponCode.value == L["不使用优惠券"] ? "" : platformCouponCode.value;

      let storeInfoList = [];
      // if (goods_data.data.length) {
      goods_data.data.map(item => {
        let storeitem = {};
        if (selCouponStore.store.storeId == item.storeId) {
          storeitem.remark = selCouponStore.store.remark;
          storeitem.storeCouponCode = selCouponStore.couponCode == L["不使用优惠券"] ? "" : selCouponStore.couponCode;
          storeitem.storeId = item.storeId;
        } else {
          storeitem.remark = item.remark;
          storeitem.storeCouponCode = item.couponCode
          storeitem.storeId = item.storeId;
        }
        storeInfoList.push(storeitem);
      });

      param.storeInfoList = storeInfoList;
      if (pre_params.data.ifcart == "true") {
        //来自于购物车
        param.isCart = true;
      } else {
        //立即购买
        param.productId = pre_params.data.productId;
        param.number = pre_params.data.numbers;
        param.isCart = false;
      }

      if (chosenInt.value > 0) {
        param.integral = chosenInt.value
      }

      if (route.query.isAloneBuy) {
        param.isAloneBuy = true
      }

      if (pre_params.data.isAloneBuy) {
        param.isAloneBuy = true
      }

      proxy.$post(
        "v3/business/front/sampleOrderOperate/confirm",
        param,
        "application/json"
      ).then(res => {
        if (res.state == 200) {
          if (type == 1) {
            goods_data.storeId = res.data.storeId;
            goods_data.storeLogo = res.data.storeLogo;
            goods_data.storeName = res.data.storeName;
            goods_data.totalAmount = res.data.totalAmount;
            goods_data.expressFee = res.data.expressFee
            goods_data.data = res.data.storeCartList;
            goods_data.data.map((item, i) => {
              item.remark = "";
              // let default_coupon = item.availableCouponList.filter(function(coupon){
              //   return coupon.checked == true;
              // });
              // if (default_coupon.length > 0) {
              //   item.couponCode = default_coupon[0].couponCode;
              //   couponCode.value[i] = default_coupon[0].couponCode
              // } else {
              //   item.couponCode = "";
              //   couponCode.value[i] = ""
              // }
            });
          } else {
            let tmpRes = res.data.storeCartList
            goods_data.data.map((item, index) => {
              // let default_coupon = tmpRes[index].availableCouponList.filter((coupon) => coupon.checked == true)
              // if (default_coupon.length > 0) {
              //   item.couponCode = default_coupon[0].couponCode;
              //   couponCode.value[index] = default_coupon[0].couponCode
              // } else {
              //   item.couponCode = "";
              //   couponCode.value[index] = ""
              // }
              item.expressFee = tmpRes[index].expressFee
              item.totalDiscount = tmpRes[index].totalDiscount
            })
          }
          allData.data = res.data

          order_settle_info.totalAmount = res.data.totalAmount;
          order_settle_info.platformCouponList = res.data.availableCouponList;
          order_settle_info.totalDiscount = res.data.totalDiscount;

          //处理平台优惠券
          // let default_plat_coupon = order_settle_info.platformCouponList.filter(
          //   function (coupon) {
          //     return coupon.checked == true;
          //   }
          // );
          // if (default_plat_coupon.length > 0) {
          //   order_settle_info.platformCouponCode =
          //     default_plat_coupon[0].couponCode;
          // } else {
          //   order_settle_info.platformCouponCode = "";
          // }

          if (type == 1) {
            virtual.isVG = allData.data.isVirtualGoods ? allData.data.isVirtualGoods : 1
            if (allData.data.reserveNameList) {
              virtual.virtualPre = allData.data.reserveNameList.map(item => {
                item.reserveValue = ''
                return item
              })
            }
          }
        } else {
          ElMessage(res.msg);
        }
      })
    };
    //获取地址列表
    const getAddressList = () => {
      proxy
        .$get("v3/member/front/memberAddress/list")
        .then(res => {
          if (res.state == 200) {
            address_list.data = res.data.list;
            if (res.data.list.length > 0 && !orderAddress.data.addressId) {
              var default_address = res.data.list.filter(function (item) {
                return item.isDefault == 1;
              });
              if (default_address.length > 0) {
                orderAddress.data = default_address[0];
              } else {
                orderAddress.data = res.data.list[0];
              }
              if (localStorage.getItem('addressId')) {
                let addressID = localStorage.getItem('addressId')
                if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                  orderAddress.data = res.data.list.filter(i => i.addressId == addressID)[0]
                }
              }
            }
            confirmOrder(1);
          } else {
            confirmOrder(2);
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //切换地址
    const selectAddress = index => {
      current_address_index.value = index;
    };
    //确认修改地址
    const confirmChangeAddress = () => {
      orderAddress.data = address_list.data[current_address_index.value];
      show_select_address.value = false;
      confirmOrder(2);
      localStorage.setItem('addressId', orderAddress.data.addressId)
    };
    //弹出地址选择框
    const changeAddress = () => {
      getAddressList();
      show_select_address.value = true;
    };
    const showAddvoice = () => {
      add_voice_content.value = true;
    };
    const removeAddvoice = () => {
      select_invoice_info.invoiceId = false;
      edit_voice_dialog.value = false;
    }
    //切换发票内容
    const changeInvoiceContent = invoiceContent => {
      invoice_info.data.invoiceContent = invoiceContent;
    };
    //切换发票抬头
    const changeInvoiceTitleType = titleType => {
      invoice_info.data.titleType = titleType;
    };
    //切换发票类型
    const changeInvoiceType = invoiceType => {
      invoice_info.data.invoiceType = invoiceType;
    };

    watch(invoice_info.data, () => {
      invoice_info.data.registerPhone = invoice_info.data.registerPhone.toString().substring(0, 11)
      invoice_info.data.receiverMobile = invoice_info.data.receiverMobile.toString().substring(0, 11)
      invoice_info.data.bankAccount = invoice_info.data.bankAccount.toString().substring(0, 19)
    })

    const invoiceCheck = (type) => {
      var reg = new RegExp("^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$");
      switch (type) {
        case 'receiverEmail': {
          if (invoice_info.data.receiverEmail && !reg.test(invoice_info.data.receiverEmail)) {
            invoice_info.data.receiverEmail = ''
            ElMessage(L["邮箱格式不正确，请重新输入！"]);
          }
          break;
        }

        case 'taxCode': {
          let regExp = /^([0-9A-Z]{15}|[0-9A-Z]{18}|[0-9A-Z]{20})$/g;
          if (invoice_info.data.taxCode && !regExp.test(invoice_info.data.taxCode)) {
            invoice_info.data.taxCode = ''
            ElMessage(L["请填写正确的税号"]);
          }
          break;
        }

        case 'registerPhone': {
          if (invoice_info.data.registerPhone && proxy.$checkMobile(invoice_info.data.registerPhone) != true) {
            invoice_info.data.registerPhone = ""
            ElMessage("请填正确填写注册电话");
          }
          break;
        }

        case 'receiverMobile': {
          if (invoice_info.data.receiverMobile && proxy.$checkMobile(invoice_info.data.receiverMobile) != true) {
            invoice_info.data.receiverMobile = ""
            ElMessage("请填正确填写收票人电话");
          }
          break;
        }
      }
    }

    //新增发票
    const confirmAddVoice = () => {
      if (invoice_info.data.titleType == 1) {
        if (invoice_info.data.invoiceTitle == "") {
          ElMessage(L["请填写发票抬头"]);
          return;
        }
        var reg = new RegExp(
          "^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$"
        );
        if (invoice_info.data.receiverEmail == "") {
          ElMessage(L["请填写收票邮箱"]);
          return;
        }
        if (!reg.test(invoice_info.data.receiverEmail)) {
          ElMessage(L["邮箱格式不正确，请重新输入！"]);
          return;
        }
      } else if (invoice_info.data.titleType == 2) {
        if (invoice_info.data.companyName == "") {
          ElMessage(L["请填写部门名称"]);
          return;
        }
        if (invoice_info.data.taxCode == "") {
          ElMessage(L["请填写纳税人税号"]);
          return;
        }
        let regExp = /^([0-9A-Z]{15}|[0-9A-Z]{18}|[0-9A-Z]{20})$/g;
        if (regExp.test(invoice_info.data.taxCode) == false) {
          ElMessage(L["请填写正确的税号"]);
          return;
        }
        if (invoice_info.data.invoiceType == 1) {
          if (invoice_info.data.receiverEmail == "") {
            ElMessage(L["请填写收票邮箱"]);
            return;
          }
        } else {
          if (invoice_info.data.registerAddr == "") {
            ElMessage(L['"请填写注册地址"']);
            return;
          }
          if (invoice_info.data.registerPhone == "") {
            ElMessage(L["请填写注册电话"]);
            return;
          }
          if (invoice_info.data.bankName == "") {
            ElMessage(L["请填写开户银行"]);
            return;
          }
          if (invoice_info.data.bankAccount == "") {
            ElMessage("请填写银行账户");
            return;
          }
          if (invoice_info.data.receiverName == "") {
            ElMessage(L["请填写收票人姓名"]);
            return;
          }
          if (invoice_info.data.receiverMobile == "") {
            ElMessage(L["请填写收票人电话"]);
            return;
          }
          if (invoice_info.data.receiverAddress == "") {
            ElMessage(L["请填写收票人地址"]);
            return;
          }
        }
      }
      var param = {};
      param.titleType = invoice_info.data.titleType;
      param.invoiceType = invoice_info.data.invoiceType;
      param.invoiceContent = invoice_info.data.invoiceContent;
      if (invoice_info.data.isDefault) {
        param.isDefault = 1;
      } else {
        param.isDefault = 0;
      }
      if (invoice_info.data.invoiceType != 2) {
        param.receiverEmail = invoice_info.data.receiverEmail;
      }
      if (invoice_info.data.titleType == 1) {
        param.invoiceTitle = invoice_info.data.invoiceTitle;
      } else {
        param.taxCode = invoice_info.data.taxCode;
        param.invoiceTitle = invoice_info.data.companyName;
        if (invoice_info.data.invoiceType == 2) {
          param.registerAddr = invoice_info.data.registerAddr;
          param.registerPhone = invoice_info.data.registerPhone;
          param.bankName = invoice_info.data.bankName;
          param.bankAccount = invoice_info.data.bankAccount;
          param.receiverName = invoice_info.data.receiverName;
          param.receiverMobile = invoice_info.data.receiverMobile;
          param.receiverAddress = invoice_info.data.receiverAddress;
        }
      }
      proxy
        .$post("v3/member/front/memberInvoice/add", param)
        .then(res => {
          if (res.state == 200) {
            if (res.state == 200) {
              select_invoice_info.invoiceTitle = invoice_info.data.titleType == 2 ? invoice_info.data.companyName : invoice_info.data.invoiceTitle;
              select_invoice_info.invoiceContent =
                invoice_info.data.invoiceContent == 1 ? L["产品明细"] : L["产品类别"];
              select_invoice_info.invoiceId = res.data;
              edit_voice_dialog.value = false;
              add_voice_content.value = false;
              resetVoiceInfo();
              ElMessage.success({
                message: res.msg,
                type: "success"
              });
            }
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //新增发票返回
    const voiceReturn = () => {
      if (invoice_list.data.length > 0) {
        add_voice_content.value = false;
      } else {
        edit_voice_dialog.value = false;
      }
      resetVoiceInfo();
    };
    //选择发票
    const chooseVoice = voice => {
      select_invoice_info.invoiceTitle = voice.invoiceTitle;
      select_invoice_info.invoiceContent =
        voice.invoiceContent == 1 ? L["产品明细"] : L["产品类别"];
      select_invoice_info.invoiceId = voice.invoiceId;
      edit_voice_dialog.value = false;
    };
    //确认订单前校验商品是否可购买
    const checkOrder = () => {

      let param = {};
      if (pre_params.data.ifcart == "true") {
        param.isCart = true;
      } else {
        param.isCart = false;
        param.productId = pre_params.data.productId;
        param.number = pre_params.data.numbers;
      }

      if (route.query.isAloneBuy) {
        param.isAloneBuy = true
      }

      reserveInfoList.value = []
      //预留信息校验start
      if (virtual.isVG == 2 && virtual.virtualPre.length > 0) {
        for (let i = 0; i < virtual.virtualPre.length; i++) {
          let { reserveName, reserveType, reserveValue, reserveNameId, isRequired } = virtual.virtualPre[i]
          if (checkPreMsg(reserveType, reserveValue, reserveName, isRequired) == true) {
            reserveInfoList.value.push({ reserveName, reserveValue, reserveNameId })
          } else {
            preMsgErr.errMsg = checkPreMsg(reserveType, reserveValue, reserveName, isRequired)
            preMsgErr.index = i
            ElMessage(checkPreMsg(reserveType, reserveValue, reserveName, isRequired))
            isPreventClick.value = false
            return
          }
        }
      }
      //预留信息校验end

      if (virtual.isVG == 2) {
        param.reserveInfoList = reserveInfoList.value
      }

      proxy
        .$post(
          "v3/business/front/orderOperate/check",
          param,
          "application/json"
        )
        .then(res => {
          if (res.state == 267) {
            no_good_info.data = res.data;
            out_stock_dialog.value = true;
            isPreventClick.value = false
          } else if (res.state == 200) {
            goBuy();
          } else {
            ElMessage(res.msg)
            isPreventClick.value = false
          }
        })
    };
    //返回上一页‘
    const returnLastPage = () => {
      out_stock_dialog.value = false;
      router.go(-1);
    };
    //移除无货产品
    const clearFailureGoods = () => {
      proxy
        .$post("v3/business/front/cart/emptyInvalid")
        .then(res => {
          if (res.state == 200) {
            out_stock_dialog.value = false;
            confirmOrder(2);
            ElMessage.success(res.msg);
          } else {
            ElMessage(res.msg);
          }
        })
    };
    //跳转订单页
    const goBuy = () => {
      if (isPreventClick.value) {
        return
      }
      isPreventClick.value = true

      if (!orderAddress.data) {
        ElMessage.warning('请新建收货地址');
        isPreventClick.value = false
        return;
      }

      let param = {
        addressId: orderAddress.data.addressId,
        orderFrom: 1
      }

      proxy
        .$post(
          "v3/business/front/sampleOrderOperate/apply",
          param
        )
        .then(res => {
          if (res.state == 200) {
            ElMessage.success(res.msg);
            setTimeout(() => {
              router.replace({
                path: "/member/sample/list"
              });
            }, 1500)
            // ElMessageBox({
            //   title: '订单提交成功',
            //   message: res.data ? '等待供应商审核' : '由于您暂未获得供应商的样品申请授权，请等待供应商进行审核。',
            //   cancelButtonText: '返回首页',
            //   confirmButtonText: res.data?'查看订单':'确定',
            //   showClose: false,
            //   showCancelButton: res.data,
            //   closeOnClickModal: false,
            //   callback: (res)=> {
            //     if(res == "confirm"){
            //       router.replace({
            //         path: "/member/sample/list"
            //       });
            //     }else{
            //       router.replace({
            //         path: "/index"
            //       });
            //     }
            //   }
            // })
            proxy.$sldStatEvent({ behaviorType: 'buy' });
          } else {
            ElMessage.error(res.msg);
            isPreventClick.value = false
          }
        })
    };
    //关闭弹窗
    const close = () => {
      addressVisibleFlag.value = false;
    };
    //弹出新建地址窗口
    const addAddress = () => {
      addressVisibleFlag.value = true;
    };
    const refreshAddress = (addressInfo, addressId) => {
      orderAddress.data = addressInfo;
      orderAddress.data.addressId = addressId;
      localStorage.setItem('addressId', orderAddress.data.addressId)
    };

    const integralEng = () => {
      chosenInt.value = tmpInt.value
      if (chosenInt.value > 0) {
        confirmOrder(2)
      }
      showIntList.value = false
    }

    const integralAban = () => {
      if (chosenInt.value > 0) {
        chosenInt.value = 0
        confirmOrder(2)
      }
      showIntList.value = false
    }

    // 积分抵扣相关参数
    const getIntRule = () => {
      proxy.$get('v3/system/front/setting/getSettings', { names: 'integral_conversion_ratio,integral_use_lowest_amount,integral_max_deduct_rate,integral_cash_out_is_enable' }).then(res => {
        if (res.state == 200) {
          intRuleList.value = res.data
        }
      })
    }

    const shIntRule = () => {
      showIntRule.value = true
    }

    //校验预留信息
    const checkPreMsg = (type, value, name, isRequired) => {
      switch (type) {
        case 1: {
          if (isRequired == 1) {
            return proxy.$checkPhone(value)
          } else {
            let regMobile = /(1[3-9]\d{9}$)/;
            if (value && !regMobile.test(value)) {
              return `请输入正确的${name}!`;
            } else {
              return true
            }
          }
          break;
        }

        case 2: {
          if (isRequired == 1) {
            return proxy.$checkIdentity(value)
          } else {
            if (value) {
              let reg18 =
                /^[1-9][0-9]{5}(18|19|20)[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{3}([0-9]|(X|x))/
              let reg15 =
                /^[1-9][0-9]{5}[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{2}[0-9]/
              if (reg18.test(value) || reg15.test(value)) {
                return true;
              } else {
                return `请输入正确的${name}`
              }
            } else {
              return true
            }
          }
          break
        }

        case 3: {
          let regNum = /[0-9]+(.[0-9]+)?/
          if (isRequired == 1) {
            if (!value) {
              return `请输入${name}`
            } else if (!regNum.test(value)) {
              return `请输入正确的${name}`
            } else {
              return true
            }
          } else {
            return true
          }
          break;
        }

        case 4: {
          if (isRequired == 1) {
            if (!value) {
              return `请输入${name}`
            } else if (type == 4) {
              return true
            }
          } else {
            return true
          }
          break;
        }

        case 5: {
          if (isRequired == 1) {
            return proxy.$checkEmail(value)
          } else {
            let reg =
              /^([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;
            if (value && !reg.test(value)) {
              return `请输入正确的${name}!`;
            } else {
              return true;
            }
          }
          break
        }
      }
    }

    onMounted(() => {
      pre_params.data = route.query;
      getAddressList();
      getIntRule()
    });

    return {
      navlist,
      not_select,
      selected,
      show_change_address,
      edit_voice_dialog,
      invoice_selected,
      add_voice_content,
      showAddvoice,
      removeAddvoice,
      out_stock_dialog,
      orderAddress,
      changeAddress,
      show_select_address,
      address_list,
      current_address_index,
      selectAddress,
      confirmChangeAddress,
      goods_data,
      pre_params,
      invoice_info,
      changeInvoiceContent,
      changeInvoiceTitleType,
      changeInvoiceType,
      confirmAddVoice,
      invoice_list,
      select_invoice_info,
      voiceReturn,
      chooseVoice,
      order_settle_info,
      confirmOrder,
      goBuy,
      returnLastPage,
      clearFailureGoods,
      no_good_info,
      addressVisibleFlag,
      close,
      type,
      refreshAddress,
      addAddress,
      couponCode,
      platformCouponCode,
      configInfo,
      rules,
      changeCoupon,
      intRuleList,
      allData,
      showIntList,
      chosenInt,
      integralEng,
      integralAban,
      showIntRule,
      shIntRule,
      L,
      tmpInt,
      invoiceCheck,
      virtual,
      preMsgErr,
    };
  },
  beforeRouteEnter(to, from, next) {
    // ...
    if (from.name == 'Login') {
      next('/index')
    } else {
      next()
    } 1
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/ConfirmRequest.scss";
</style>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.voice_width {
  width: 605px !important;
}

.add_voice_btn2 {
  margin: -30px 114px 10px !important;
}

.out_store_width {
  width: 480px !important;
}

.select_address_width {
  width: 500px !important;
}

.el-dialog__body {
  padding-top: 10px;
}

.el-input__inner {
  font-size: 12px;
  line-height: 1px !important;
  /**
     * 解决el-input设置类型为number时，中文输入法光标上移问题
     **/
}

.el-select .el-input__inner:focus {
  border-color: $colorMain;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: $colorMain;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: $colorMain;
  outline: 0;
}

.validateMessage {
  font-size: 12px;
  color: #e2231a;
  margin-top: 5px;
  padding-left: 80px;
  height: 12px;
}

.giftProduct {
  display: inline-block;
  border: 1px solid #e2231a;
  color: #e2231a;
  width: 33px;
  height: 16px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-right: 4px;
}

.integral_dialog .el-dialog__body {
  padding: 0;
}

#pre_tag_input {
  height: 30px;
  width: 320px;
}

.preMsgErr {
  color: #e2231a;
  margin-left: 13px;
}

.el-radio {
  margin-right: 20px;
}

.el-radio__label {
  width: 70px;
  padding-left: 5px;
}

.good_logistics .el-input {
  margin-right: 15px;
}

.good_logistics .el-input__inner {
  width: 415px;
  height: 32px;
  padding-left: 8px;
}

.store_item_bottom_item .el-input {
  width: 600px;
  height: 32px;
  line-height: 32px;
}

.store_item_bottom_item .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.store_item_bottom_date .el-input {
  width: 138px;
}

.receive_info_type .el-radio__input.is-checked .el-radio__inner {
  border-color: $colorMain;
  background: $colorMain;
}

.receive_info_type .el-radio__input.is-checked+.el-radio__label {
  color: $colorMain;
}

.el-popup-parent--hidden {
  overflow: visible;
  padding-right: 0 !important;
}
</style>